html {
	box-sizing: border-box;
	background-color: $gray-white3;
	font-size: calc(0.50em + 0.3vw);
	font-size: -webkit-calc(0.50em + 0.3vw);
	font-size: -moz-calc(0.50em + 0.3vw);
	@media (max-width: 576px) {
		font-size: 0.50em;
	}
	@media (min-width: 1800px) {
		font-size: 0.84em;
	}
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
	position: relative;
	background-color: $white;
}

em {
	font-weight: bold;
}

.hide-vis {
	display: none;
}

.jumbotron-fluid {
	position: relative;
	z-index: 1;
}

.container-fluid {
	width: 100%;
	//max-width: 100% !important;
}

.container {
	max-width: 100% !important;
}

.container-max {
	width: 80vw;
	max-width: 1800px;
	-webkit-transition: width 0.1s linear;
	-moz-transition: width 0.1s linear;
	-ms-transition: width 0.1s linear;
	-o-transition: width 0.1s linear;
	transition: width .1s linear;
	@media (max-width: 1760px) { width: 81vw; }
	@media (max-width: 1720px) { width: 82vw; }
	@media (max-width: 1679px) { width: 83vw; }
	@media (max-width: 1638px) { width: 84vw; }
	@media (max-width: 1598px) { width: 85vw; }
	@media (max-width: 1558px) { width: 86vw; }
	@media (max-width: 1517px) { width: 87vw; }
	@media (max-width: 1477px) { width: 88vw; }
	@media (max-width: 1436px) { width: 89vw; }
	@media (max-width: 1396px) { width: 90vw; }
	@media (max-width: 1356px) { width: 91vw; }
	@media (max-width: 1315px) { width: 92vw; }
	@media (max-width: 1275px) { width: 93vw; }
	@media (max-width: 1234px) { width: 94vw; }
	@media (max-width: 1194px) { width: 95vw; }
	@media (max-width: 1153px) { width: 96vw; }
	@media (max-width: 1113px) { width: 97vw; }
	@media (max-width: 1073px) { width: 98vw; }
	@media (max-width: 1032px) { width: 99vw; }
	@media (max-width: 991.9px) { width: 100vw; }
}

.col-md-border:not(:last-child) {
	@media (min-width: 992px) {
		border-right: 1px solid $gray-light1;
	}
	@media (max-width: 991.9px) {
		border-bottom: 1px solid $gray-light1;
	}
}

.col-md-border + .col-md-border {
	@media (min-width: 992px) {
		margin-left: -1px;
	}
	@media (max-width: 991.9px) {
		margin-top: -1px;
	}
}

thead.thead-branded {
	tr th {
		color: $gray-mid3;
		font-weight: 400;
		font-size: 1.5em;
		border-top: none;
		border-bottom: 2px solid $brandcolor1;
		@media (max-width: 991.9px) {
			font-size: 1.25em;
		}
		@media (max-width: 768px) {
			font-size: 1.125em;
		}
	}
}

tbody.tbody-branded {
	tr td {
		line-height: 1;
		border-top: 1px dotted $gray-light3;
		@media (max-width: 991.9px) {
			font-size: 0.885em;
		}
		@media (max-width: 768px) {
			font-size: 0.75em;
		}
	}
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: $gray-white1;
}
