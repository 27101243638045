@charset 'UTF-8';

@import "../node_modules/bootstrap/scss/_functions.scss";
@import "../node_modules/bootstrap/scss/_variables.scss";
@import "../node_modules/bootstrap/scss/mixins/_breakpoints.scss";

@import
	'abstracts/variables',
	'abstracts/functions',
	'abstracts/mixins';

@import
	'base/base',
	'base/fonts',
	'base/typography',
	'base/dividers',
	'base/helpers',
	'base/utility';

@import
	'components/navigation',
	'components/header',
	'components/footer',
	'components/forms',
	'components/banners',
	'components/backgrounds',
	'components/blocks',
	'components/buttons',
	'components/cards',
	'components/images',
	'components/video',
	'components/intro',
	'components/page-title',
	'components/pagination',
	'components/sliders',
	'components/testimonials',
	'components/modals';

@import
	'templates/home',
	'templates/careers',
	'templates/contact',
	'templates/markets',
	'templates/news',
	'templates/projects',
	'templates/search',
	'templates/services',
	'templates/studio';
