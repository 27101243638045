.page-title-full-width {
	position: absolute;
	background-color: $gray-white2;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	// -webkit-background-size: cover !important;
	// -moz-background-size: cover !important;
	// -o-background-size: cover !important;
	// background-size: cover !important;
	// background-repeat: no-repeat !important;
	// background-position: center top;
	// background-attachment: fixed;
	// -webkit-filter: blur(20px) grayscale(25%);
	// -moz-filter: blur(20px) grayscale(25%);
	// -o-filter: blur(20px) grayscale(25%);
	// filter: blur(20px) grayscale(25%);
	// opacity: 0.75;
}

.page-title-image {
	width: 100vw;
	max-width: 2200px;
	height: auto;
}

.page-title-video-container {
	width: 100vw;
	max-width: 2200px;
	height: auto;
}

h1.page-title {
	display: inline-block;
	margin: 0;
	padding: 0 0 0.25em 0.5em;
}

.page-title-overlay-container {
	// background: linear-gradient(180deg, rgba(61,50,43,0) 0%, rgba(61,50,43,0.75) 100%);
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 6.875em;
	z-index: 2;
	.page-title-container {
		h1.page-title {
			color: $white;
		}
	}
}

.page-title-image-overlay {
	background: linear-gradient(180deg, rgba(61,50,43,0) 0%, rgba(61,50,43,0.75) 100%);
	position: absolute;
	left: 50%;
	bottom: 0;
	width: 100%;
	max-width: 2200px;
	height: 6.875em;
	transform: translateX(-50%);
}

.page-title-marker {
	position: relative;
	&::after {
		content:"";
		display: inline-block;
		position: absolute;
		left: 0.75em;
		bottom: 0;
		width: calc(25% - 3.0em);
		height: 0.625em;
		background-color: $white;
		z-index: 2;
	}
}

.page-title-no-back {
	h1.page-title {
		padding: 1.2em 0 0.25em 0.4em;
		color: $black;
	}
}

.page-title-backlink-container {
	padding: 1.25em 0 3.0em 2.25em !important;
	.page-title-backlink {
		position: relative;
		overflow: hidden;
	}
}
