// Search Results Page
.search-results-all-container {
	padding: 0 2.5em 7.0em 2.5em;
	@media (max-width: 768px) {
		padding: 0 1.25em 7.0em 1.25em; 
	}
	ul.list-group-flush {
		li.list-group-item {
			margin: 0;
			padding: 1.0em 0 0.5em 0;
			&:hover {
				p {
					color: $black;
				}
			}
			h5 {
				margin-bottom: 0.0625em;
				a {
					color: $black;
					&:hover {
						color: $brandcolor1;
						text-decoration: none;
					}
					&:active, &:focus, &:not(:disabled):not(.disabled):focus {
						color: $brandcolor1-dark;
						text-decoration: none;
					}
					.fac {
						font-size: 0.75em;
						color: $brandcolor1;
						transform: translateY(-0.0625em);
					}
				}
			}
			p {
				font-size: 0.9375em;
				color: $gray-mid2;
				line-height: 1.5;
			}
		}
	}
}


//Search Form Modal
#modal-search-fs {
	backdrop-filter: blur(6px);
	.modal-dialog {
		.modal-content {
			background-color: $white;
			border-radius: 0;
			border: none;
			.modal-body {
				padding: 1.25em 0.625em 1.25em 2.5em;
				box-shadow:
					2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
					6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
					12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
					22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
					41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
					100px 100px 80px rgba(0, 0, 0, 0.07);
				input {
					font-size: 1.75em !important;
					color: $black !important;
					border: none !important;
					border-bottom: 1px solid $gray-light3 !important;
					&.active.focus, &.active:focus, &.focus, &.focus:active, &:active:focus, &:focus {
						outline: 0 !important;
						outline-offset: 0  !important;
						background-image: none  !important;
						-webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0) !important;
						box-shadow: 0 0 8px rgba(0, 0, 0, 0)  !important;
					}
				}
				button {
					margin: 0;
					padding: 0.625em 1.25em 0 1.25em;
					.fac {
						color: $brandcolor1;
					}
					&.active.focus, &.active:focus, &.focus, &.focus:active, &:active:focus, &:focus {
						outline: 0 !important;
						outline-offset: 0  !important;
						background-image: none  !important;
						-webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0) !important;
						box-shadow: 0 0 8px rgba(0, 0, 0, 0)  !important;
					}
				}
			}
		}
	}
}

.modal-backdrop.show{
	background-color: rgba($white,0.5);
}