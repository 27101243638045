// Services Main Page


//Service Callouts Block
.service-callout-cards-container {
	margin: 0;
	padding: 1.25rem;
}
.service-callout-card {
	margin: 0;
	padding: 0.625rem;
	&:hover {
		.img-zoom-hover {
			img {
				transform: scale(1.125);
			}
		}
		.card-body a h5 {
			color: $brandcolor1;
		}
	}
	.card-body {
		margin: 0;
		padding: 1.0em 1.0em 2.5em 1.0em;
		h5 {
			margin: 0;
			padding: 0;
			font-weight: 100;
			font-size: 2.25rem;
			color: $gray-mid2;
			position: relative;
			-webkit-transition: color 1.0s ease-out;
			-moz-transition: color 1.0s ease-out;
			-ms-transition: color 1.0s ease-out;
			-o-transition: color 1.0s ease-out;
			transition: color 1.0s ease-out;
			&::after {
				content: "\f000";
				font-family: 'InvisionArchitecture';
				display: inline-block;
				font-size: 0.625em;
				font-weight: normal;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				text-rendering: auto;
				position: absolute;
				top: 0.375em;
				right: 0;
				color: $brandcolor1;
			}
		}
		a {
			&:hover {
				text-decoration: none;
				h5 {
					color: $brandcolor1;
				}
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				text-decoration: none;
				h5 {
					color: $brandcolor1-dark;
				}
			}
		}
		p {
			font-size: 1.175rem !important;
		}
	}
}