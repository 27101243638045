.core-team-grid-header {
	padding: 0 0.625em 0 0.625em;
}

.core-team-list-header {
	padding: 0;
}

.core-team-card-row, .core-team-list-row {
	padding: 0 0 3.75em 0;
}

.people-card {
	position: relative;
	margin: 0.5em !important;
	&:hover {
		.img-zoom-hover {
			img {
				transform: scale(1.125);
			}
		}
		.card-overlay .card-body {
			background: rgba($black, 0.4);
			backdrop-filter: blur(6px);
			a h5 {
				color: $white;
				&::after {
					opacity: 1;
				}
			}
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		.card-overlay .card-body {
			a h5 {
				color: $brandcolor1-light;
				&::after {
					color: $brandcolor1-light;
				}
			}
		}
	}
	.card-img-top {
		width: 100% !important;
		height: 100% !important;
		min-width: 100%;
		min-height: 100%;
		.img-zoom-hover {
			width: 100% !important;
			height: 100% !important;
			min-width: 100%;
			min-height: 100%;
		}
	}
	.card-overlay {
		background: linear-gradient(180deg, rgba(61,50,43,0) 0%, rgba(61,50,43,0.75) 100%);
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 2.5em;
		z-index: 2;
	}
	.card-body {
		margin: 0;
		padding: 0.5em 1.0em 0.5em 1.0em;
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		-webkit-transition: background 1.0s ease-out, backdrop-filter 2.0s ease-out;
		-moz-transition: background 1.0s ease-out, backdrop-filter 2.0s ease-out;
		-ms-transition: background 1.0s ease-out, backdrop-filter 2.0s ease-out;
		-o-transition: background 1.0s ease-out, backdrop-filter 2.0s ease-out;
		transition: background 1.0s ease-out, backdrop-filter 2.0s ease-out;
		&:hover {
			background: rgba($black, 0.4);
			backdrop-filter: blur(6px);
		}
		h5 {
			position: relative;
			margin: 0;
			padding: 0;
			color: $white;
			font-weight: 100;
			&::after {
				content: "\f000";
				font-family: 'InvisionArchitecture';
				display: inline-block;
				font-size: 0.625em;
				font-weight: normal;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				text-rendering: auto;
				position: absolute;
				top: 0.375em;
				right: 0;
				color: $white;
				opacity: 0;
				-webkit-transition: opacity 1.0s ease-out;
				-moz-transition: opacity 1.0s ease-out;
				-ms-transition: opacity 1.0s ease-out;
				-o-transition: opacity 1.0s ease-out;
				transition: opacity 1.0s ease-out;
			}
		}
		a {
			&:hover {
				text-decoration: none;
				h5 {
					color: $white;
					&::after {
						opacity: 1;
					}
				}
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				text-decoration: none;
				h5 {
					color: $white;
				}
			}
		}
	}
}

.nav-studio-view {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 0 0.8125em !important;
	.nav-item {
		.nav-link {
			padding: 0.3125em 0;
			margin: 0 1.25em;
			&.active {
				margin-bottom: -0.3125em;
				border-bottom: 0.3125em solid $brandcolor1;
			}
		}
	}
	li {
		&::marker {
			content: "" !important;
			display: none !important;
		}
	}
}

.studio-staff-card-container {
	margin: 0 0.625em 7.5em 0.625em;
}

.studio-staff-list-container {
	margin: 0.625em 0 7.0em 0;
	padding: 0 1.0em;
	// border-top: 1px solid $gray-mid1;
	.studio-staff-list-row {
		padding: 0.75em 0 0 0;
		border-bottom: 1px solid $gray-white3;
		&:first-child { padding-top: 1.5em }
		&:last-child { border-bottom: none; }
		.studio-staff-list-name,
		.studio-staff-list-title,
		.studio-staff-list-phone,
		.studio-staff-list-email  {
			font-size: 1.1875rem;
			a {
				color: $black;
				&:hover {
					color: $brandcolor1;
					text-decoration: none;
				}
				.fac {
					color: $brandcolor1;
				}
			}
		}
		.studio-staff-list-name {
		}
		.studio-staff-list-title {

		}
		.studio-staff-list-phone {
			font-size: 1.0rem;
		}
		.studio-staff-list-email {
			font-size: 1.0rem;
			text-transform: uppercase;
		}
	}
}

.studio-staff-header-row {
	background-color: $gray-white2;
	.studo-staff-header-container {
		padding: 0 2.5em 0 0;
		@media (max-width: 991.9px) {
			padding: 0;
		}
	}
	.studio-staff-header {
		margin: 0;
		padding: 2.5em 2.5em 1.875em 2.5em;

		.link-text-nav {
			margin-bottom: 2.5em;
		}

		h1 {
			margin: 0 0 0.15625em 0;
			padding: 0;
		}

		h6 {
			margin: 0;
			padding: 0;
			text-transform: uppercase;
			color: $black;
			font-weight: 600;
			letter-spacing: 0.0625em;
		}

		.page-title-marker {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 0.625em;
			&::after {
				content:"";
				display: inline-block;
				position: absolute;
				left: 2.5em;
				bottom: 0;
				width: calc(50% - 3.0em);
				height: 0.625em;
				background-color: $white;
				z-index: 2;
			}
		}

		@media (max-width: 991.9px) {
			.studio-staff-name {
				margin-top: 1.0em !important;
			}
		}
	}
}

.studio-staff-back-container {
	padding: 1.0em 1.5em;		
}

.studio-staff-info-container {
	padding: 2.5em;
	.studio-staff-body-container {
		.section-back-link {
			display: block;
			margin: 0 0 2.5em 0;
		}
		h6 {
			text-transform: uppercase;
			font-size: 0.8em;
			padding-bottom: 0.625em;
		}
	}
	.studio-staff-traits-container {
		column-count: 2;
		@include media-breakpoint-only(lg) {
			column-count: 1;
		}
		@include media-breakpoint-only(xs) {
			column-count: 1;
		}
		@media (max-width: 991.9px) {
			padding: 3.75em 0;
		}
		.card {
			display: inline-block;
			column-break-inside: avoid;
			padding: 0 1.25em;
			width: 100%;
		}
	}
}