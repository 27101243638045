/* Testimonial Block */
.testimonial-slider-full-width-container {
	background-color: $gray-white1;
	padding: 6.25em 0 5.0em 0;
	.carousel-control-prev, .carousel-control-next {
		padding: 0.3125em;
		color: $gray-mid2;
	}
}
.testimonial-card {
	margin: 0;
	padding: 0;
	width: 95%;
	// min-height: 12.0em;
	.card-body {
		padding: 1.25em 1.125em 0em 1.125em;
	}
	.testimonial-copy, .testimonial-copy p {
		font-weight: 400;
		font-size: 1.125em;
		line-height: 1.5;
		&::before {
			content: '“';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			font-size: 6.0rem;
			font-weight: 400;
			color: $brandcolor1;
			transform: translate(-2.25rem, -2.5rem);
		}
	}
	.testimonial-teaser-headline {
		margin: 1.25em 0 0 0;
		h5 {
			font-size: 1.0rem;
			color: $gray-mid2;
			text-transform: initial;
			font-weight: 400;
			letter-spacing: initial;
			b {
				color: $black;
				text-transform: uppercase;
				font-weight: 700;
				letter-spacing: 0.05em;
			}
		}
	}
}
