.job-listings-container {
	margin: 1.25em 0 5.0em 0;
	padding: 0;
}

.job-listing-card {
	margin-bottom: 3.75em;
	.card-body {
		padding: 0 0.3125em;	
		a {
			h3 {
				color: $gray-dark2;
				margin-bottom: 0.25em;
				padding-bottom: 0.125em;
				border-bottom: 1px solid $gray-light1;
				.fac, .fa {
					font-size: 0.625em;
					transform: translateY(-0.125em);
					color: $brandcolor1;
				}
			}
			&:hover {
				h3 {
					color: $brandcolor1;
					text-decoration: none;
				}
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				h3 {
					color: $brandcolor1-dark;
					text-decoration: none;
				}
			}
		}
		.job-listing-card-category {
			margin: 0em 0 1.875em 0;			
		}
		.job-listing-card-headline {
			padding-bottom: 0.0625em;
		}
		.job-listing-card-subheadline {
			color: $gray-mid2;
			padding-bottom: 0.0625em;
		}
		.job-listing-card-teaser-copy {
			font-size: 0.9375em;
			color: $black;
		}
	}
}

.careers-resume-footer-container {
	padding: 5.0em 0;
	background-color: $gray-white1;
	.careers-resume-footer-text-container {
		padding: 0 2.0em;
		h1,h2,h3 {
			color: $brandcolor1;
		}
		h4,h5,h6 {
			color: $black;
		}
		p {
			color: $black;
		}
	}
	.careers-resume-footer-button-container {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 0 2.0em;
		@media (max-width: 768px) {
			justify-content: center;
		}
		.btn {
			white-space: nowrap;
		}
	}
}

.entry-type-jobListing {
	.job-listing-title-container {
		.job-listing-title {
			margin-bottom: 0.25em;
		}
		.job-listing-category {
			margin-bottom: 0;
			color: $black;
			font-size: 0.8125em;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 0.025em;
		}
	}
	.job-listing-headline-container {
		margin: 0 0 1.25em 0.75em;
		padding-bottom: 0.0625em;
		border-bottom: 1px solid $gray-light1;
		.job-listing-headline {
			margin-bottom: 0.25em;
		}
		.job-listing-subheadline {
			margin-bottom: 0.25em;
		}
	}
	.job-listing-body-container {
		margin-bottom: 2.5em;
	}
	.job-listing-form-container {
		margin-bottom: 7.5em;
		hr.divider-gray {
			margin: 3.75em 0 3.75em 0;
		}
		.job-listing-form {
			margin-top: 2.5em;
		}
	}
}
