// News Article Teaser Cards
.news-card {
	width: calc(100% - 1.25em);
	margin: 0 0 2.5em 0;
	&:hover {
		.img-zoom-hover {
			img {
				transform: scale(1.125);
			}
		}
		.card-body {
			.news-article-teaser-headline h6 {
				color: $brandcolor1;
				text-decoration: none;
			}	
		}
		.card-footer a {
			color: $brandcolor1-dark;
			text-decoration: none;
			.fa, .fac {
				color: $brandcolor1-dark;
			}
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		.card-body {
			.news-article-teaser-headline h6 {
				color: $brandcolor1-dark;
				text-decoration: none;
			}	
		}
		.card-footer a {
			color: $brandcolor1-dark;
			text-decoration: none;
			.fa, .fac {
				color: $brandcolor1-dark;
			}
		}
	}
	.news-article-teaser-thumb-container-newsThumbnailWide {	
		display: block;
		@media (max-width: 568px) {
			display: none;
		}
	}
	.news-article-teaser-thumb-container-newsThumbnail {	
		display: none;
		@media (max-width: 568px) {
			display: block;
		}
	}
	.card-body {
		padding: 1.25em 1.125em 0em 1.125em;
		.news-article-teaser-headline h6 {
			font-size: 1.125rem;
			color: $black;
			font-weight: 300;
		}
		.news-article-teaser-copy {
			color: $gray-mid2;
			font-size: 0.8em;
			line-height: 1.25;
		}
	}
	.card-footer {
		padding: 0.3125em 1.125em;
	}
}

//News Article Detail
.news-headline-row {
	padding: 3.75em 0 2.5em 0;
	.news-headline-text {
		margin-bottom: 0.0625em;
	}
	.news-subheadline-text {
		margin-bottom: 0.3125em;
		color: $gray-mid2;
	}
	.news-author-headline {
		margin: 0 0.125em;
		color: $black;
		font-size: 1.25em;
		font-weight: 300;
		a {
			color: $black;
			&:hover {
				color: $brandcolor1;
				text-decoration: none;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $brandcolor1-dark;
				text-decoration: none;
			}
		}
	}
	.news-headline-date {
		margin: 1.25em 0.3125em 0 0.3125em;
		color: $black;
		font-size: 0.75em;
		font-weight: 700;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}
}
.news-article-all {
	margin-bottom: 6.25em;
}
.news-article-blocks {
	margin: 0 0.875em;
}
.news-article-featured-image {
	margin-bottom: 3.75em;
}
.news-article-body {
	margin-bottom: 1.25em;
}

//Related News Block
.news-related-full-width-container {
	padding: 5.0em 0 1.25em 0;
	.news-related-container {
		padding: 0 1.5625em;
		.news-related-header-row {
			padding: 0 0.8175em 1.25em 0.8175em;
			h5 {
				margin: 0;
				padding: 0;
			}
		}
	}
}

