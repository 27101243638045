.card, .card-footer, .card-header {
	background: none;
	border: none;
	border-radius: 0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	a {
		&:hover {
			text-decoration: none;
		}
	}
	// -webkit-transition: all 0.5s ease-out;
	// -moz-transition: all 0.5s linear;
	// -ms-transition: all 0.5s ease-out;
	// -o-transition: all 0.5s ease-out;
	// transition: all 0.5s ease-out;
}
.card {
	width: calc(100% - 1.25em);
	margin: 0 0 2.5em 0;
	.card-img-top {
		border-radius: 0;
		position: relative;
		width: 100%;
		height: auto;
		.card-img-overlay {
			position: absolute;
			left:  0;
			right: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background: $gray-mid3;
			background: -moz-linear-gradient(180deg, rgba($gray-mid3,0) 33%, rgba($gray-mid3,0.6) 100%);
			background: -webkit-linear-gradient(180deg, rgba($gray-mid3,0) 33%, rgba($gray-mid3,0.6) 100%);
			background: linear-gradient(180deg, rgba($gray-mid3,0) 33%, rgba($gray-mid3,0.6) 100%);
			img { 
				position: absolute;
				margin: 0;
				padding: 0.3125em 0.3125em;
				left: 1.125em;
				bottom: -1px;
				width: 17.5%;
				height: auto;
				border-bottom: 5px solid $white;
			}
		}
	}
	.card-body {
		color: $gray-mid2;
		padding: 1.25em 1.125em;
		p {
			font-size: 0.8em;
			line-height: 1.5;
		}
		h5, .entry-teaser-headline {
			color: $black;
			font-weight: 300;
			margin-bottom: 0.25em;
		}
		.entry-teaser-copy {
			margin-top: 0.5em;
		}
	}
	.card-footer {
		padding: 0.3125em 1.125em;
	}
}