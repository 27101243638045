// .carousel {
// 	width: 100%;
// 	height: auto;
// }

.carousel-item {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.carousel-image {
	width: 100%;
	height: auto;
	visibility: hidden;
}

.banner-title-overlay {
	position: absolute;
	left: 0;
	bottom: -0.25em;
	width: 100%;
	max-width: 1800px;
	padding: 0;
	margin: 0;
	a.banner-title-link {
		background-color: $white;
		color: $gray-mid2;
		font-size: 0.6em;
		line-height: 1;
		margin: 0 0 0 10%;
		padding: 0.75em 2.0em;
		-webkit-transition: color 1.0s ease-out;
		-moz-transition: color 1.0s ease-out;
		-ms-transition: color 1.0s ease-out;
		-o-transition: color 1.0s ease-out;
		transition: color 1.0s ease-out;
		&:hover {
			color: $brandcolor1;
			text-decoration: none;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $brandcolor1-dark;
			text-decoration: none;
		}
		.fa, .fac {
			color: $brandcolor1;
			font-size: 1.0em;
			// transform: translateY(-0.00625em);
		}
	}
}

.carousel-caption {
	position: absolute;
	top: 25%;
	left: 25%;
	transform: translateY(-25%);
	transform: translateX(-25%);
	width: 33%;
	height: auto;
	max-width: 900px;
	margin: 0;
	padding: 0;
	@media (max-width: 576px) {
		top: 12% !important; 
		left: 15% !important; 
		transform: translate(0,0) !important;
	}
	h1 {
		font-size: 2.4em;
		margin: 0;
		padding: 0;
	}
	.caption-headline-block {
		position: relative;
		padding-bottom: 0;
	}
	hr.caption-divider {
		overflow: visible; /* For IE */
		padding: 0;
		margin: 0 0 0.625em 0;
		border: none;
		border-top: 1px solid $brandcolor1;
		color: $white;
		text-align: right;
	}
	.caption-body {
		padding-top: 0;
		p {
			line-height: 1;
		}
	}
}

.carousel-control-prev, .carousel-control-next {
	width: auto;
	padding: 1.25em;
	background-image: none;
	border-radius: 0;
	opacity: 1.0;
	color: $white;
	z-index: 2;
	-webkit-transition: color 1.0s ease-out;
	-moz-transition: color 1.0s ease-out;
	-ms-transition: color 1.0s ease-out;
	-o-transition: color 1.0s ease-out;
	transition: color 1.0s ease-out;
	&:hover {
		color: $brandcolor1;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark;
	}
}

.carousel-control-prev-icon, .carousel-control-next-icon {
	background-image: none;
	font-family: 'InvisionArchitecture';
	display: inline-block;
	font-size: inherit;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	text-rendering: auto;
	font-size: 1.25em;
	line-height: 1;
}

.carousel-control-prev-icon::after {
	content: "\f001";
}

.carousel-control-next-icon::after {
	content: "\f000";
}

.carousel-indicators {
	right: 10%;
	justify-content:flex-end;
	margin: 1.0em 0;
	z-index: 2;
}

.carousel-indicators li {
	width: 0.625em;
	height: 0.625em;
	border-radius: 0;
	background: none;
	border: 0.125em solid $white;
	opacity: 1.0;
	margin: 0 0.1em !important;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-webkit-transition: background-color 1.0s ease-out, border 1.0s ease-out;
	-moz-transition: background-color 1.0s ease-out, border 1.0s ease-out;
	-ms-transition: background-color 1.0s ease-out, border 1.0s ease-out;
	-o-transition: background-color 1.0s ease-out, border 1.0s ease-out;
	transition: background-color 1.0s ease-out, border 1.0s ease-out;
	&:hover {
		background-color: $white;
		border: 0.3125em solid $white;
	}
	&.active {
		background-color: $brandcolor1;
		border: 0.125em solid $brandcolor1;
	}
}

#banner-down-arrow {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1;
	text-align: center;
}

#banner-down-arrow a {
	position: absolute;
	bottom: 2.0em;
	left: 0;
	width: 100vw;
	-webkit-animation: anim-banner-down-arrow 2s infinite;
	animation: anim-banner-down-arrow 2s infinite;
	.fa, .fac {
		font-size: 2.0em;
		color: $white;
		&:hover {
			color: $brandcolor1;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $brandcolor1-dark;
		}
	}
}

@-webkit-keyframes anim-banner-down-arrow {
	0% {
		-webkit-transform: translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(2.0em);
		opacity: 0;
	}
}

@keyframes anim-banner-down-arrow {
	0% {
		transform: translate(0, 0);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: translateY(2.0em);
		opacity: 0;
	}
}