.entry-pages-contact {
	h2 {
		color: $brandcolor1;
	}
	h3 {
		// color: $black;
		// font-size: 1.75em;
		// font-weight: 600;
		// text-transform: uppercase;
		// letter-spacing: 0.05em;
	}
	.contact-locations-container {
		padding: 0 0 7.5em 0; 
		color: $gray-mid2;
		.contact-address-container {
			// padding: 2.5em;
			padding: 1.25em;
			@media (max-width: 991.9px) {
				padding: 2.25em;
			}
			@media (max-width: 575.5px) {
				.col-sm-4 {
					padding: 2.0em;
				}
			}
			address {
				color: $black;
			}
			a {
				color: $black;
				&:hover {
					color: $brandcolor1;
					text-decoration: none;
				}
			}
			b {
				font-weight: 400;
				text-transform: uppercase;
			}
		}
		.contact-photo-map-container {
			.contact-photo-container {
				padding: 2.5em 1.25em 2.5em 2.5em;
				@media (max-width: 576px) {
					padding: 2.5em;
				}
			}
			.contact-map-container {
				padding: 2.5em 2.5em 2.5em 0;
				@media (max-width: 576px) {
					padding: 2.5em;
				}
			}
		}
	}
	.contact-form-container-outer {
		background-color: $gray-white1;
		padding: 7.5em 0;
		.contact-form-container-inner {
			padding: 0 2.5em;
			@media (max-width: 768px) {
				padding: 3.75em;
			}
			label {
				color: $gray-mid2;
				font-weight: 600;
				text-transform: uppercase;
				letter-spacing: 0.05em;
				margin-top: 0.3125em;
				margin-bottom: 0.3125em;
			}
		}
	}
}