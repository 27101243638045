//Project Teaser Cards
.project-card {
	margin: 0 0 1.5em 0;
	&:hover {
		.img-zoom-hover {
			img {
				transform: scale(1.125);
			}
		}
		.card-body {
			h5, .project-teaser-headline {
				color: $brandcolor1;
				text-decoration: none;
			}	
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		.card-body {
			h5, .project-teaser-headline {
				color: $brandcolor1-dark;
				text-decoration: none;
			}	
		}
	}
	h5, .project-teaser-headline {
		margin-bottom: 0.25em;
		color: $black;
		line-height: 1.125em;
		//font-weight: 400 !important;
	}
	h6 {
		color: $gray-mid2;
	}
	.project-teaser-copy {
		margin-top: 0.5em;
	}
	.card-body {
		margin: 0 0 2.0em 0;
	}
}

//Related Projects Include
.projects-related-full-width-container {
	background-color: $gray-white1;
	padding: 5.0em 0 1.25em 0;
	.projects-related-header-row {
		padding: 0 0.625em 0.625em 0.625em;
	}
	.projects-related-container {
		padding: 0 1.625em;
	}
}

// Project Main Page
.header-image-slider-full-width {
	background-color: $gray-white2;
	.project-header-image-slider {
		width: 100vw;
		max-width: 2200px;
		height: 85vh;
		// max-height: 85vh;
		overflow: hidden;
		.carousel-item {
			display: flex;
			position: relative;
			justify-content: center;
			align-items: center;
			height: 100%;	
			.carousel-image {
				visibility: hidden;
				width: auto;
				height: 100%;
			}
			.carousel-overlay {
				position: absolute;
				z-index: 100;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: rgba($gray-white2,0.5);
				-webkit-backdrop-filter: blur(6px) grayscale(100%) contrast(50%);
				-moz-backdrop-filter: blur(6px) grayscale(100%)contrast(50%);
				-o-backdrop-filter: blur(6px) grayscale(100%)contrast(50%);
				backdrop-filter: blur(6px) grayscale(100%)contrast(50%);
				.carousel-image {
					visibility: visible;
					width: 100%;
					height: auto;
				}
			}
		}
	}
}

.project-header-row {
	padding: 2.0em 0 4.0em 0;
	.project-header {
		font-size: 0.875em;
		padding: 0 1.5em;
		@media (max-width: 1200px) {
			padding: 0 1.125em;
		}
		@media (max-width: 992px) {
			padding: 0;
		}
		.project-header-back-link {
			margin-bottom: 1.0em;
		}
		.project-header-name {
			margin-top: 1.0em;
			margin-bottom: 0;
		}
		.project-header-location {
			margin: 0.625em 0 0 0;
			font-size: 1.125em;
		}
	}
}

.project-next-prev-container {
	border-left: 1px solid $gray-white3;
	@media (max-width: 768px) {
		padding: 2.0em 0 0 0 !important;
		border-left: none;
	}
	.project-prev-container, .project-next-container {
		white-space: nowrap;
		font-size: 0.8em;
		@media (max-width: 768px) {
			padding: 0;
		}
		a {
			color: $gray-mid2;
			&:hover {
				color: $brandcolor1;
				text-decoration: none;
			}
			.fa, .fac {
				font-size: 1.875em;
				transform: translateY(0.2em);
			}
		}
	}
	.project-prev-container {
		text-align: left;
	}
	.project-next-container {
		text-align: right;
	}
}

.project-team-container {
	padding: 3.0em 2.0em;
	.project-leads-photo-container {
		.project-leads-photo {
			margin-bottom: 1.25em;
		}
	}
	.project-leads-name-container {
		.project-lead-intro-card {
			padding-bottom: 0.265em;
			margin-bottom: 0.25em;
			a {
				&:hover {
					h5 {
						color: $brandcolor1;
					}
				}
				h5.project-lead-name {
					font-size: 1.25em;
					font-weight: 600;
					margin-bottom: 0.125em;
				}
			}
		}
		p {
			color: $gray-mid2;
			font-size: 0.875em;
			line-height: 1.125;
		}
	}
	.project-team-other-container {
		padding: 1.25em 0;
		h5.header-sm {
			color: $black;
		}
		ul li {
			border-bottom: none !important;
			padding: 0.3215em 0;
			&:first-child { padding-top: 1.5em }
			a {
				color: $gray-mid2;
				font-size: 1.25em;
				&:hover {
					color: $brandcolor1;
				}
				&::after {
					display: none;
				}
			}
		}
	}
}

.project-video-gallery-container {
	margin: 0 0 2.5em 0;
	//padding: 0 1.25em 0 0 !important;
	@media (max-width: 768px) {
		padding: 0 !important;
	}
	.project-video-block-title {
		margin: 0.625em 0.8175em 1.25em 0.8175em;
		padding-bottom: 0.625em;
		border-bottom: 1px solid $gray-mid1;
	}
}

.project-video-card {
	margin: 0;
	padding: 0;
	&:hover {
		.video-thumb-overlay {
			background-color: rgba($white, 0.5);
			.video-thumb-button {
				color: $brandcolor1;
				.link-text-nav {
					color: $brandcolor1;
				}
			}
		}
		.card-body {
			h6, .project-video-card-headline {
				//color: $black;
				text-decoration: none;
				b {
					color: $brandcolor1;
					text-decoration: none;
				}
			}	
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		.card-body {
			h6, .project-video-card-headline {
				//color: $black;
				text-decoration: none;
				b {
					color: $brandcolor1-dark;
					text-decoration: none;
				}
			}	
		}
	}
	.card-body {
		margin: 0;
		padding: 0.9375em 0.0625em 1.5625em 0.0625em;
		h6, .project-video-card-headline {
			font-size: 0.875em;
			b {
				font-weight: 400;
				color: $black;
			}
		}
	}
}