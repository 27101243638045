#intro {
	p.lead {
		// color: $black;
	}
}

#our-process {
	h3.text-one-column-headline-text {
		font-size: 2.4em;
	}
	h5 {
		color: $black;
	}
	h5.image-callout-headline {
		font-size: 1.0em;
		font-weight: 600;
		color: $brandcolor1;
	}
	.image-callout-body {
		color: $black;
		font-size: 1.25em;
	}
}

#respecting-environment, #community-engagement {
	.text-one-column-headline-text {
		color: $brandcolor1;
	}
}