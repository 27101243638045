@mixin modal-fullscreen() {
  padding: 0 !important; // override inline padding-right added from js

  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-body {
    overflow-y: auto;
  }

}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .modal-fullscreen#{$infix} {
      @include modal-fullscreen();
    }
  }
}

.modal-backdrop.show {
  opacity: 0.8;
}
