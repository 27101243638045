input, textarea {
	color: $gray-mid2 !important;
	font-size: 1.125em !important;
	width: 100%;
	padding: 0.625em !important;
	border: 1px solid $gray-light2 !important;
	border-radius: 0 !important;
	box-shadow:inset 0 2px 0 $gray-white2;
	-moz-box-shadow:inset 0 2px 0 $gray-white2;
	-webkit-box-shadow:inset 0 2px 0 $gray-white2;
	&::placeholder  {
		color: $gray-light2 !important;
	}
}

select {
	color: $gray-mid2 !important;
	font-size: 1.125em !important;
	width: 100%;
	border: 1px solid $gray-light2 !important;
	border-radius: 0 !important;
	box-shadow:inset 0 -2px 0 $gray-white1;
	-moz-box-shadow:inset 0 -2px 0 $gray-white2;
	-webkit-box-shadow:inset 0 -2px 0 $gray-white2;
	&::after {
		color: $brandcolor1 !important;
	}
}

label.required {
	color: $gray-mid2;
	&::after {
		color: $brandcolor1;
	}
}

.form-label-required {
	color: $brandcolor1;
}

.form-check-input:not(:checked), .form-check-input:checked {
	position: absolute;
	pointer-events: none;
	opacity: 0;
}

.btn-group-toggle {
	margin: 0 1.25em;
}

.btn-outline-group {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	font-weight: 400 !important;
	text-transform: none !important;
	letter-spacing: 0 !important;
	background-color: none !important;
	color: $gray-dark2 !important;
	border: 1px solid $gray-light3 !important;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1 !important;
		border: 1px solid $gray-light3 !important;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1-light !important;
		border: 1px solid $gray-light3 !important;
		box-shadow: 0 !important;
		outline: none !important;
	}
}

.btn-outline-group:not(:disabled):not(.disabled):active,
.btn-outline-group:not(:disabled):not(.disabled).active,
.show>.btn-outline-group.dropdown-toggle {
	color: $white !important;
	background-color: $gray-dark1 !important;
 }

.custom-select {
	background: $white url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
}

.custom-checkbox
.custom-control-input:checked~.custom-control-label::after {
		background-color: $brandcolor1;
}
.form-control:focus {
	border-color: $brandcolor1;
	box-shadow: inset 0 1px 1px rgba($brandcolor1, 0.075), 0 0 8px rgba($brandcolor1, 0.6);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

button.active.focus, button.active:focus, button.focus, button.focus:active, button:active:focus, button:focus {
	outline: 0 !important;
	outline-offset: 0  !important;
	background-image: none  !important;
	-webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.25) !important;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.25)  !important;
}

/* Freeform Plugin Form Inputs */
.btn-freeform {
	margin-top: 1.25em;
}
.freeform-form-container {
}

form.inline-form {
	display: flex;
	margin: 0;
	padding: 0;
	input,
	label,
	.btn {
		transition: opacity 300ms linear;
	}
	.input-wrapper {
		flex-grow: 1;
		input,
		label {
			margin: 0 !important;
			height: auto;
			opacity: 1.0;
			transition: opacity 300ms linear;
			font-size: inherit !important;
			text-transform: uppercase;
			line-height: 1;
			box-shadow: none !important;
			-moz-box-shadow: none !important;
			-webkit-box-shadow: none !important;
		}
		input {
			padding: .25rem 0 !important;
			outline: none !important;
			border: none !important;
			font-weight: bold;
			box-sizing: border-box;
			border-spacing: 0;
			vertical-align: baseline;
			opacity: 0.0;
		}
		input:focus {
			outline-color: transparent !important;
			outline-style: none !important;
			box-shadow: none;
			opacity : 1.0;
		}
		label {
			padding: .5rem 0 !important;
			pointer-events: none;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 1.0;
		}
		input:focus + label {
			opacity: 0;
		}
	}
	.btn {
		margin: 0;
		padding: .1em .5em;
		font-size: inherit !important;
		opacity: 0.0;
		pointer-events: none;
	}
}
form:focus-within {
	input,
	.btn {
		opacity: 1.0 !important;
		pointer-events: initial;
	}
	label {
		opacity: 0.0 !important;
	}
}
