.link-text-nav, .link-text-subnav {
	color: $black;
	font-weight: 600;
	font-size: calc(0.75rem + 0.2vw);
	font-size: -webkit-calc(0.75rem + 0.2vw);
	font-size: -moz-calc(0.75rem + 0.2vw);
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	-webkit-transition: color 0.5s ease-out;
	-moz-transition: color 0.5s ease-out;
	-ms-transition: color 0.5s ease-out;
	-o-transition: color 0.5s ease-out;
	transition: color 0.5s ease-out;
	&:hover {
		color: $brandcolor1 !important;
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark !important;
		text-decoration: none;
	}
	.fac {
		color: $brandcolor1;
	}
	@media (max-width: 576px) {
		font-size: 1.0em;
	}
	@media (min-width: 1800px) {
		font-size: 0.875rem;
	}
}

.link-text-subnav {
	color: $gray-mid2 !important;
	font-weight: 400;
	.fac {
		font-size: 0.8em;
		transform: translateY(-0.125em);
		color: $gray-mid2;
		&:hover {
			color: $brandcolor1;
		}
		&:active, &:focus, &:not(:disabled):not(.disabled):focus {
			color: $brandcolor1;
		}
	}
}

.link-text-sm {
	color: $brandcolor1;
	font-weight: 700;
	font-size: 0.7em;
	line-height: 1;
	-webkit-transition: color 0.5s ease-out;
	-moz-transition: color 0.5s ease-out;
	-ms-transition: color 0.5s ease-out;
	-o-transition: color 0.5s ease-out;
	transition: color 0.5s ease-out;
	&:hover {
		color: $brandcolor1-dark;
		text-decoration: none;
		.fa, .fac {
			color: $brandcolor1-dark;
		}
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark;
		text-decoration: none;
		.fa, .fac {
			color: $brandcolor1-dark;
		}
	}
	.fa, .fac {
		font-size: 0.8em;
		transform: translateX(0.25em);
	}
}

header {
	margin: 0;
	//padding: 0.5em 1.0em 1.0em 1.0em;

	.header-container {
		// margin: 0;
		// padding: 0;
	}

	nav .nav-tabs {
		position: relative;
		z-index: 50;
	}
	nav .navbar-nav {
		margin: 0;
		padding: 0;
	}

	nav .nav-link, .link-text-nav {
		color: $black;
		font-weight: 600;
		// font-size: 0.8em;
		line-height: 1;
		text-transform: uppercase;
		letter-spacing: 0.025em;
		padding: 0.5em 0 0.5em calc(0.25em + 0.55vw) !important;
		padding: 0.5em 0 0.5em -webkit-calc(0.25em + 0.55vw) !important;
		padding: 0.5em 0 0.5em -moz-calc(0.25em + 0.55vw) !important;
		font-size: calc(0.5em + 0.34vw);
		font-size: -webkit-calc(0.5em + 0.34vw);
		font-size: -moz-calc(0.5em + 0.34vw);
		@media (max-width: 1680px) {
			font-size: 0.8125em;
			padding: 0.5em 0 0.5em 1.0em !important;
		}
		@media (max-width: 1520px) {
			font-size: 0.75em;
			padding: 0.5em 0 0.5em 0.5em !important;
		}
		@media (max-width: 1320px) {
			font-size: 0.6875em;
			padding: 0.5em 0 0.5em 0.5em !important;
		}
		@media (max-width: 1220px) {
			font-size: 0.625em;
			padding: 0.5em 0 0.5em 0.5em !important;
		}
		@media (min-width: 1800px) {
			font-size: 0.875em;
			padding: 0.5em 0 0.5em 1.75em !important;
		}
	}

	nav .nav-item {
		margin: 0;
		&:hover {
			.nav-link {
				color: $brandcolor1;
				-webkit-transition: color 0.5s ease-out;
				-moz-transition: color 0.5s ease-out;
				-ms-transition: color 0.5s ease-out;
				-o-transition: color 0.5s ease-out;
				transition: color 0.5s ease-out;
			}
		}
		&.active {
			.nav-link {
				color: $brandcolor1 !important;
			}
		}
	}
}

.navbar-toggler {
	//border: 2px solid $gray-dark2 !important;
	//display: none; /* temporary for the one-page site. Delete to have mobile navigation again */
}

.navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(99,84,80,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar {
	li {
		display: block;
		transition-duration: 0.5s;
		&:hover {
			cursor: default;
			&.pointer {
				cursor: pointer;
			}
		}
	}

	ul li ul {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		transition: all 0.5s ease-out;
		display: none;
		a {
			color: $white;
			background-color: $gray-dark2;
			border: 1px solid $white;
			padding: 0.625em 1.875em !important;
		}
	}

	ul li:hover > ul,
	ul li ul:hover {
		visibility: visible;
		opacity: 1;
		display: block;
	}

	ul li ul li {
		clear: both;
		width: 100%;
	}
}

.sidebar-container,
.subpage-container {
	&.sidebar-container {
		margin: 0;
		padding: 0 2.0em;
		@media (max-width: 768px) {
			padding: 0 0.625em;
		}
	}
	.navbar-label {
		padding-top: 0.5em;
	}
	.navbar-nav {
		margin: 0;
		padding: 0;
		border-top: 2px solid $brandcolor1;
		li {
			display: block;
			margin: 0;
			padding: 0.33em 0em;
			border-bottom: 1px solid $gray-light1;
			&.active {
				.nav-link {
					color: $brandcolor1;
				}
			}
		}
	}
	&.subpage-container {
		width: calc(100% - 1.25em);
		margin-bottom: 2.5em;
		padding: 0;
		.navbar-toggler {
			position: relative;
			padding: 0.75em 0 0.75em 0.75em;
			border-bottom: 2px solid $brandcolor1;
			text-align: left;
			font-size: 1.2em;
			// text-transform: uppercase;
			color: $gray-mid2;
			&::after {
				content: "\f002";
				font-family: 'InvisionArchitecture';
				display: inline-block;
				font-size: 0.625em;
				font-weight: normal;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				text-rendering: auto;
				position: absolute;
				top: 1.5em;
				right: 1.25em;
				color: $black;
			}
		}
		.navbar-label {
			display: none;
		}
		.navbar-nav {
			border-top: none;
		}
	}

	@supports ((position:-webkit-sticky) or (position:sticky)) {
		.sidebar-sticky {
			top: 10em;
			z-index: 1010;
			padding-bottom: 5em;
		}
	}
}

@media (max-width: 1199.9px) {
	.header-full #navbarCollapse {
		position: absolute;
		top: 130%;
		right: -1em;
		width: 100vw;
		height: 100vh;
		font-size: 2.5em;
		background: $white;
		> .navbar-nav {
			margin: 1em 0.625em !important;
			max-height: 100%;
			overflow-y: scroll;
			li {
				a {
					background: none;
					padding: 0.5em 0.5em !important;
					border-bottom: 1px solid $gray-white3;
				}
			}
			ul {
				display: block;
				position: relative;
				opacity: 1;
				visibility: visible;
				margin: 0;
				padding: 0;
				.nav-item {
					display: block;
				}
				li {
					a {
						background: none;
						padding: 0.5em 0.5em;
						border-bottom: 1px solid $gray-white3;
						color: $black !important;
					}
				}
			}
		}
	}
}

.nav-tabs {
	border-bottom: none;
	padding-bottom: 1.25em;
	.nav-link {
		border-radius: 0;
		border: none;
	}
}
