.body, body { 
	font-family: proxima-nova, Roboto, Helvetica, Calibri, 'Segoe UI', san-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 1.1175em;
	line-height: 1.4em;
	color: $black;
}

.body {
	ul {
		list-style: none; /* Remove default bullets */
		margin: 1.0em 0 1.0em -0.1em;
	}

	ul li::marker {
		content: "\2580";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
		color: $brandcolor1; /* Change the color */
		font-weight: bold; /* If you want it to be bold */
		// display: inline-block;  /*Needed to add space between the bullet and the text */
		// width: 1.25em; /* Also needed for space (tweak if needed) */
		// padding-right: 2em; /* Also needed for space (tweak if needed) */
		// transform: translateX(-1.1em);
		font-size: 0.5em;
	}

	ul li {
		line-height: 1.125;
		margin: 0.8175em -0.5em;
		padding-left: 0.5em;
	}

	ul li a {
		// font-weight: 400;
		// text-decoration: underline;
		// color: $black !important;
	}

	ul li a:hover {
		// color: $brandcolor1 !important;
	}

	ol li {
		line-height: 1.25;
		margin: 0.66em 0;
	}
}

ul.list-unstyled {
	margin: 1.0em 0;
	li::before {
		content: "";
		display: none;
		margin-left: 0;
	}
}

p {
}

p.lead, .lead p {
	color: $gray-mid2;
	font-weight: 400;
	font-size: 1.5rem;
	line-height: 1.5;
}

p.text-small, .text-small p, .text-small {
	color: $gray-mid2;
	font-size: 0.8em;
	line-height: 1.25;
}

a {
	color: $brandcolor1;
	text-decoration: none;
	&:hover {
		color: $brandcolor1;
		text-decoration: underline;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		color: $brandcolor1-dark;
		text-decoration: none;
	}
}

h1 {
	color: $black;
	font-weight: 100;
	font-size: 3.2em;
	line-height: 1.125;
	margin-bottom: 0.33em;
}

h2 {
	color: $black;
	font-weight: 100;
	font-size: 2.4em;
	line-height: 1.125;
	margin-bottom: 0.33em;
}

h3 {
	color: $gray-mid2;
	font-weight: 100;
	font-size: 1.8em;
	line-height: 1.125;
	margin-bottom: 0.5em;
}

h4 {
	color: $brandcolor1;
	font-weight: 100;
	font-size: 1.5em;
	line-height: 1.125;
	margin-bottom: 0.5em;
}

h5 {
	color: $gray-mid2;
	font-weight: 400;
	font-size: 1.5rem;
	line-height: 1.5;
	margin-bottom: 0.75em;
}

h6 {
	color: $gray-mid2;
	font-weight: 400;
	font-size: 1.0em;
	line-height: 1.25;
	margin-bottom: 0.5em;
}

h5.header-sm {
	color: $brandcolor1;
	font-weight: 600;
	font-size: 0.8125em;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	margin-bottom: 0.75em;
}

h6.header-xs {
	color: $black;
	font-weight: 600;
	font-size: 0.625em;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	margin-bottom: 0.75em;
}

.text-light, .text-light p, .text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6, .text-light ul li::before, .text-light ul li::before {
	color: $white;
}

h1.large-quote {
	vertical-align:bottom;
	font-size: 5.5em;
	line-height: 1;
}

h2.large-quote {
	vertical-align:bottom;
	font-size: 4.5em;
	line-height: 1;
}

h2.page-intro-headline-text {
	margin-bottom: 0.3125em;
}

h4.page-intro-subheadline-text {
	margin-top: 0;
}

ul.info-detail-list {
	list-style-type: none;
	margin: 0 0 2.5em 0;
	padding: 0;
	color: $gray-dark1;
	border-top: 1px solid $gray-mid1;
	li {
		margin: 0;
		padding: 0.625em 0;
		font-size: 0.8em;
		line-height: 1.25;
		border-bottom: 1px solid $gray-white3;
		&:first-child { padding-top: 1.5em }
		&:last-child { border-bottom: none; }
		&::marker {
			content: "";
			display: none;
		}
		b {
			color: $black;
			font-weight: 600;
		}
		a {
			display: block;
			position: relative;
			font-weight: 600;
			color: $black;
			&::after {
				content: "\f000";
				font-family: 'InvisionArchitecture';
				display: inline-block;
				font-size: 0.625em;
				font-weight: normal;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				text-rendering: auto;
				position: absolute;
				top: 0.375em;
				right: 0.75em;
				color: $gray-mid2;
			}
			&:hover {
				color: $brandcolor1;
				text-decoration: none;
				&::after {
					color: $brandcolor1;
				}
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $brandcolor1-dark;
				text-decoration: none;
				&::after {
					color: $brandcolor1-dark;
				}
			}
		}
	}
}

.columns2 {
	column-count: 2;
	column-gap: 2.5em;
	@include media-breakpoint-only(lg) {
		column-count: 1;
	}
	@include media-breakpoint-only(xs) {
		column-count: 1;
	}
	p {
		// break-inside: avoid-column;
	}
}

.columns3 {
	column-count: 3;
	column-gap: 2.5em;
	@include media-breakpoint-only(lg) {
		column-count: 1;
	}
	@include media-breakpoint-only(xs) {
		column-count: 1;
	}
	p {
		// break-inside: avoid-column;
	}
}

.brandcolor1 {
	color: $brandcolor1;
}
