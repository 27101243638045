//Add img-fluid responsiveness to all img tags within the body section.
.body {
	img {
		max-width: 100%;
		height: auto;
	}
}

.img-zoom-hover {
	overflow: hidden;
	&:hover {
		img {
			transform: scale(1.125);
		}
	}
	img {
		-webkit-transition: transform 3.0s ease-out;
		-moz-transition: transform 3.0s ease-out;
		-ms-transition: transform 3.0s ease-out;
		-o-transition: transform 3.0s ease-out;
		transition: transform 3.0s ease-out;
	}
}

.img-marker-link-under,
.img-marker-link-right {
	position: relative;
	&::after {
		content: "";
		display: inline-block;
		position: absolute;
		left: 16px;
		bottom: -4px;
		width: 6.25em;
		height: 4px;
		background-color: $brandcolor1;
	}
}

.img-marker-link-right {
	&::before {
		content: "";
		display: inline-block;
		position: absolute;
		top: 1.26em;
		right: -4px;
		width: 4px;
		height: 6.25em;
		background-color: $brandcolor1;
	}

	@media (min-width:992px) {
		&::before { display: inline-block; }
		&::after { display: none; }
	}
	@media (max-width:992px) {
		&::before { display: none; }
		&::after { display: inline-block; }
	}
	@media (max-width:768px) {
		&::before { display: inline-block; }
		&::after { display: none; }
	}
	@media (max-width:576px) {
		&::before { display: none; }
		&::after { display: inline-block; }
	}
}

.video-thumb-container {
	position: relative;
	&:hover {
		.video-thumb-overlay {
			background-color: rgba($white, 0.5);
			.video-thumb-button {
				color: $brandcolor1;
				.link-text-nav {
					color: $brandcolor1;
				}
			}
		}
	}
	.video-thumb-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($black, 0.1);
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		-webkit-transition: all 2.0s ease-out;
		-moz-transition: all 2.0s ease-out;
		-ms-transition: all 2.0s ease-out;
		-o-transition: all 2.0s ease-out;
		transition: all 2.0s ease-out;
		.video-thumb-button {
			color: $white;
			text-align: center;
			margin-left: 1.75em;
			padding-bottom: 0.9375em;
			.link-text-nav {
				display: block;
				color: $white;
				font-size: 0.8125em;
				margin: 0.9375em 0 0 0;
			}
		}
	}
}