// Blocks General
.background-image-overlay {
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	// -webkit-background-size: cover;
	// -moz-background-size: cover;
	// -o-background-size: cover;
	// background-size: cover;
	// background-repeat: no-repeat;
	background-position: center;
}

.block-headline-text {
	margin: 0;
	line-height: 1;
}
h3.block-headline-text {
	color: $brandcolor1;
}

.block-subheadline-text, .block-subheadline-text p {
	margin: 0.3125em 0 0 0;
	text-transform: none;
	line-height: 1;
}

// Images
.img-regular-padding {
	padding: 0 1.25em;
	@media (max-width: 991.9px) {
		padding: 2.5em 1.25em;
	}
}

.img-no-padding {
	padding: 0;
	@media (max-width: 991.9px) {
		padding: 2.5em 0;
	}
}

.img-square-crop, .img-circle-crop {
	position:relative;
}
.img-square-crop::after, .img-circle-crop::after {
	content: "";
	display: block;
	padding-bottom: 100%;
}
.img-square-crop img, .img-circle-crop img {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-repeat: no-repeat;
	@media (max-width: 991.9px) {
		padding: 2.5em;
	}
}

.img-circle-crop img {
	border-radius: 50%;
}

.img-overlay-sections {
	position: absolute;
	right: 2.5em;
	top: 0;
	@media (max-width: 991.9px) {
		position: static;
		padding: 2.5em 1.25em;
	}
}


// Text One Column Block
.text-one-column-body-copy {
	display: block;
	margin-top: 0.3125em;
}

// Large Text Callout Block Style
.large-text-callout-body-copy {
	p {
		font-size: 2.0em;
		line-height: 1.2em;
	}
}

h2.large-text-callout-headline-text {
	font-size: 1.8em;
}
h3.large-text-callout-headline-text {
	font-size: 1.5em;
}
h4.large-text-callout-headline-text {
	font-size: 1.2em;
}

h4.large-text-callout-subheadline-text {
	font-size: 1.2em;
}
h5.large-text-callout-subheadline-text {
	font-size: 1.0em;
}
h6.large-text-callout-subheadline-text {
	font-size: 0.8em;
}

//Pull Quote Block Style
.pull-quote-body-copy {
	p {
		font-size: 1.5em;
		line-height: 1.2em;
	}	
}

h2.pull-quote-headline-text {
	font-size: 1.8em;
}
h3.pull-quote-headline-text {
	font-size: 1.5em;
}
h4.pull-quote-headline-text {
	font-size: 1.2em;
}

h4.pull-quote-subheadline-text {
	font-size: 1.2em;
}
h5.pull-quote-subheadline-text {
	font-size: 1.0em;
}
h6.pull-quote-subheadline-text {
	font-size: 0.8em;
}


//Text with Image Block
.block-text-image-body-column {
	padding: 0 2.5em !important;
}



//Stat Facts Row
.stat-fact-number {
	display: inline;
	margin: 0;
	color: $gray-dark1;
	font-size: 5em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-prefix {
	display: inline;
	margin: 0;
	color: $gray-dark1;
	font-size: 3em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-suffix {
	display: inline;
	margin: 0;
	color: $gray-dark1;
	font-size: 3em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-desc {
	margin: 0;
	font-size: 1.2em;
	line-height: 1;
}
.stat-fact-citation {
	margin: 0;
	padding-top: 0.625em;
	font-size: 0.9em;
	font-weight: 300;
	line-height: 1;
	color: $gray-mid3;
}


//Pull Quote
.pull-quote-body p {
	font-size: 2.5em;
	line-height: 1.2em;
}
.pull-quote-citation {
	font-size: 1.2em;
	line-height: 1em;
}


//Image Callout Cards
.image-callout-card {
}

h3.image-callout-headline {
	color: $brandcolor1;
}
h5.image-callout-headline {
	color: $brandcolor1;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.05em;
	margin-bottom: 0.125em;
}
h6.image-callout-subheadline {
	color: $black;
}

.image-callout-body {
	display: block;
	margin-top: 0.75em;
}



//Image Icon Row
.image-icon-link {
	&:hover {
		text-decoration: none;
	}
}
.image-icon-headline {
	// color: $black;
	padding-top: 1.125em;
}

.image-icon-text p {
	color: $gray-mid2;
	font-size: 0.8em;
	line-height: 1.5;
	padding: 1.125em 0.625em;
}
.image-icon-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
}


//Video Block
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0; overflow:
	hidden;
	max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


//Image Gallery Block
.block-container-image-gallery {
	.block-image-gallery-thumb-container {
		padding: 0.3125em;
		.block-image-gallery-thumb {

		}
	}
	.block-image-gallery-masonry-grid-sizer,
	.block-image-gallery-masonry-grid-item { 
		padding: 0.3125em;
	}
}



//Market Callouts Block
.market-callout-card {
	&:hover {
		.img-zoom-hover {
			img {
				transform: scale(1.125);
			}
		}
		.card-body a h5 {
			color: $brandcolor1;
		}
	}
	.card-body {
		margin: 0;
		padding: 1.0em 1.0em 2.5em 1.0em;
		h5 {
			margin: 0;
			padding: 0;
			font-weight: 100;
			color: $gray-mid2;
			position: relative;
			-webkit-transition: color 1.0s ease-out;
			-moz-transition: color 1.0s ease-out;
			-ms-transition: color 1.0s ease-out;
			-o-transition: color 1.0s ease-out;
			transition: color 1.0s ease-out;
			&::after {
				content: "\f000";
				font-family: 'InvisionArchitecture';
				display: inline-block;
				font-size: 0.625em;
				font-weight: normal;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				text-rendering: auto;
				position: absolute;
				top: 0.375em;
				right: 0;
				color: $brandcolor1;
			}
		}
		a {
			&:hover {
				text-decoration: none;
				h5 {
					color: $brandcolor1;
				}
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				text-decoration: none;
				h5 {
					color: $brandcolor1-dark;
				}
			}
		}
	}
}


//Community Engagement Block;
.community-partner-slider {
	width: 100%;
	height: calc(45px + 2.5em);
	margin: 0;
	padding: 1.25em 0 0 0;
	border-left: 1px solid $white;
	border-right: 1px solid $white;
	.community-partner-slider-wrapper {
		-webkit-transition-timing-function: linear !important;
		-moz-transition-timing-function: linear !important;
		-ms-transition-timing-function: linear !important;
		-o-transition-timing-function: linear !important;
		transition-timing-function: linear !important;
		.community-partner-slide {
			display: block;
			width: auto;
			max-height: 45px;
			.community-partner-image {
				width: auto;
				max-height: 45px;
			}
		}
	}
	.community-partner-slider-overlay {
		position: absolute;
		pointer-events: none;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		background: -moz-linear-gradient(90deg, rgba($white,1) 0%, rgba($white,0) 25%, rgba($white,0) 75%, rgba($white,1) 100%);
		background: -webkit-linear-gradient(90deg, rgba($white,1) 0%, rgba($white,0) 25%, rgba($white,0) 75%, rgba($white,1) 100%);
		background: linear-gradient(90deg, rgba($white,1) 0%, rgba($white,0) 25%, rgba($white,0) 75%, rgba($white,1) 100%);
		border-left: 1px solid $white;
		border-right: 1px solid $white;
	}
}
