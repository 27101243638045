.header-full {
	background-color: $white;
	padding: 2.0em 0 0.75em 0;
	// position: sticky;
	// z-index: 100;
	// width: 100%;
}

.header-container {
	padding: 0 2.5em;
	background-color: $white;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	//max-width: 2200px;
}

header .navbar-brand {
	//width: 260px;
	//height: 66px;
	width: calc(195px + 3.625vw);
	height: calc(49.5px + 0.875vw);
	background: url("/assets/images/branding/INVISION_Logo.svg") left center/contain no-repeat;
	text-indent: -9999px;
	margin: 0.125em 1.0em 0.125em 0em;
	z-index: 3;
	@media (max-width: 991.9px) {
		width: 195px;
		height: 49.5px;
		margin: 0.125em 1.0em 0.125em 0em;
	}
	@media (min-width: 1800px) {
		width: 260px;
		height: 66px;
	}
}
