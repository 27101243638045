.project-header-slider {
	min-height: auto;
	&.loading {
		min-height: 66vh;
	}
	width: 100vw;
	//** height: calc(100vh - 10em);
	background-color: $gray-light1;
	.project-header-slider-wrapper {
		.project-header-slide {
			display: inline-block;
			width: auto;
			// height: 100vh; // Some browsers, including Safari need a height in this context
			//max-width: 100vw;
			//max-height: calc(100vh - 10em);
			overflow: hidden;
			background-color: $gray-light1;
			.project-header-image {
				// width: auto;
				// height: 100%;
				max-width: 100vw;
				max-height: calc(100vh - 10em);
				opacity: 0.25;
				border: none;
				-webkit-filter: blur(0px) grayscale(100%) contrast(50%);
				-moz-filter: blur(0px) grayscale(100%)contrast(50%);
				-ms-filter: blur(0px) grayscale(100%)contrast(50%);
				-o-bfilter: blur(0px) grayscale(100%)contrast(50%);
				filter: blur(0px) grayscale(100%)contrast(50%);
				-webkit-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-moz-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-ms-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				-o-transition: filter 1.0s ease-out, opacity 1.0s ease-out;
				transition: filter 1.0s ease-out, opacity 1.0s ease-out;
			}
		}
		.swiper-slide-active {
			.project-header-image {
				opacity: 1.0;
				border: none;
				-webkit-filter: blur(0) grayscale(0) contrast(100%);
				-moz-filter: blur(0) grayscale(0) contrast(100%);
				-ms-filter: blur(0) grayscale(0) contrast(100%);
				-o-bfilter: blur(0) grayscale(0) contrast(100%);
				filter: blur(0) grayscale(0) contrast(100%);
			}
		}
	}
}

.project-slider-video-thumb-container {
	position: relative;
	&:hover {
		.video-thumb-overlay {
			background-color: rgba($black, 0.75);
			.video-thumb-button {
				color: $brandcolor1;
				.link-text-nav {
					color: $brandcolor1;
				}
			}
		}
	}
	.video-thumb-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($black, 0.25);
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		-webkit-transition: all 2.0s ease-out;
		-moz-transition: all 2.0s ease-out;
		-ms-transition: all 2.0s ease-out;
		-o-transition: all 2.0s ease-out;
		transition: all 2.0s ease-out;
		.video-thumb-button {
			color: $white;
			text-align: center;
			margin-left: 1.75em;
			padding-bottom: 0.9375em;
			.link-text-nav {
				display: block;
				color: $white;
				font-size: 0.8125em;
				margin: 0.9375em 0 0 0;
			}
		}
	}
}

.swiper-button-next, .swiper-button-prev {
	&::after {
		font-size: 1.75em;
		color: $brandcolor1;
	}
	&:hover {
		&::after {
			color: $brandcolor1-dark;
		}
	}
	&:focus-visible {
		outline: none;
	}
}

.swiper-pagination {
	.swiper-pagination-bullet {
		width: 0.625em;
		height: 0.625em;
		border-radius: 0;
		background: none;
		border: 0.125em solid $white;
		opacity: 1.0;
		margin: 0 0.1em !important;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		// -webkit-transition: background-color 0.5s ease-out, border 0.5s ease-out;
		// -moz-transition: background-color 0.5s ease-out, border 0.5s ease-out;
		// -ms-transition: background-color 0.5s ease-out, border 0.5s ease-out;
		// -o-transition: background-color 0.5s ease-out, border 0.5s ease-out;
		// transition: background-color 0.5s ease-out, border 0.5s ease-out;
		&:hover {
			background-color: $white;
			border: 0.3125em solid $white;
		}
	}
	.swiper-pagination-bullet-active {
		background-color: $brandcolor1;
		border: 0.125em solid $brandcolor1;
	}
}
