.footer {
	background-color: $gray-white3;
	color: $gray-mid2;
	font-size: 0.78em;
	line-height: 1.1em;
	margin: 0;
	padding: 0;
	@media (max-width: 768px) {
		font-size: 1.0em;
	}

	.footer-inner {
		padding: 5.0em 4.0em 3.0em 4.0em;

		a {
			color: $black;
			-webkit-transition: color 0.5s ease-out;
			-moz-transition: color 0.5s ease-out;
			-ms-transition: color 0.5s ease-out;
			-o-transition: color 0.5s ease-out;
			transition: color 0.5s ease-out;
			i.fac {
				color: $brandcolor1;
				font-size: 0.6em;
				transform: translateY(-0.125em);
			}
			&:hover {
				color: $brandcolor1;
				text-decoration: none;
			}
		}

		a address {
			color: $gray-mid2;
			&:hover {
				color: $brandcolor1;
				text-decoration: none;
			}
		}

		.header-xs {
			display: block;
			font-size: 0.8em;
			line-height: 1;
		}

		b  {
			font-weight: 600;
			color: $black;
		}

		.footer-col-container-1 {
			display: flex;
			justify-content: flex-start;
			@media (max-width: 768px) {
				justify-content: center;
				padding-bottom: 2em;
			}
		}
		.footer-col-container-2 {
			display: flex;
			justify-content: flex-end;
			@media (max-width: 768px) {
				justify-content: center;
			}
		}
		.footer-col-1, .footer-col-2 {
			display: inline-block;
			width: auto;
			@media (max-width: 768px) {
				width: 100%;
			}
		}

		.footer-logo-container {
			padding: 0 0 2.0em 0;
			text-align: left;

			.footer-logo {
				display: inline-block;
				width: 212px;
				height: 54px;
				background: url("/assets/images/branding/INVISION_Logo_Footer.svg") left center/contain no-repeat;
				text-indent: -9999px;
				@media (max-width: 1300px) {
					width: 212px;
					height: 54px;
				}
			}
		}

		.footer-locations-container {
			padding: 0 0 2.0em 0;
			white-space: nowrap;
		}

		.footer-address-container {
			padding: 0 2.5em 0 0;
			@media (max-width: 768px) {
				padding: 1.5em 0;
			}
		}

		.footer-subscribe-container {
			padding: 0 0 2.0em 0;
			text-align: right;

			.footer-subscribe-label {
				color: $brandcolor1;
			}

			.footer-subscribe-input {
				margin: 0.5em 0 0 0;
				background-color: $gray-white3;
				color: $black;
				&.active.focus, &.active:focus, &.focus, &.focus:active, &:active:focus, &:focus {
					outline: 0 !important;
					outline-offset: 0  !important;
					background-image: none  !important;
					-webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0) !important;
					box-shadow: 0 0 8px rgba(0, 0, 0, 0)  !important;
				}
			}

			form {
				.mc-field-group {
					.input-group-append {
						flex-grow: 0;
						max-width: 0px;
						opacity: 0.0;
						transition: all 300ms ease-out;
						.btn {
							padding-bottom: 0;
							padding-top: 0.25em;
							padding-right: 0.0em;
							padding-left: 0.25em;
							margin-right: 0;
							.fac {
								font-size: 2.3125rem;
								color: $brandcolor1;
							}
						}
					}
				}
				&:focus-within {
					.mc-field-group {
						.input-group-append {
							max-width: 100px;
							opacity: 1.0;
						}
					}
				}
			}
		}

		.footer-links-container {
			padding: 0 0 2.0em 0;
			white-space: nowrap;
			ul {
				margin: 0;
				li {
					padding:0.25em 0;
					text-transform: uppercase;
					a {
						color: $gray-mid2;
						-webkit-transition: color 0.5s ease-out;
						-moz-transition: color 0.5s ease-out;
						-ms-transition: color 0.5s ease-out;
						-o-transition: color 0.5s ease-out;
						transition: color 0.5s ease-out;
						&:hover {
							color: $brandcolor1;
							text-decoration: none;
						}
					}
				}
			}
		}

		.footer-social-container {
			text-align: right;
			.footer-social-desc {
				display: none;
			}
			.footer-social-icon {
				color: $gray-mid2;
				font-size: 2.5em;
				line-height: 1;
				-webkit-transition: color 1.0s ease-out;
				-moz-transition: color 1.0s ease-out;
				-ms-transition: color 1.0s ease-out;
				-o-transition: color 1.0s ease-out;
				transition: color 1.0s ease-out;
				&:hover {
					color: $brandcolor1;
				}
			}
		}

		.footer-copyright {
			color: $gray-light3;
			text-align: center;
			padding: 3.0em 1.0em 0 1.0em;
		}
	}
}

#modal-subscribe {
	background-color: rgba($white, 0.5);
	backdrop-filter: blur(6px);
	.modal-dialog {
		.modal-content {
			background-color: $white;
			border-radius: 0;
			border: none;
			.modal-header {
				border-radius: 0;
				border: none;
				.fac {
					color: $black;
				}
			}
			.modal-body {
				padding: 0;
				border: none;
				margin: 0;
				box-shadow:
					2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
					6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
					12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
					22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
					41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
					100px 100px 80px rgba(0, 0, 0, 0.07);
				iframe[name="subscribe-results"] {
					border: none;
					height: 50vh;
					width: 100%;
				}
			}
		}
	}
}
